import $ from 'jquery';

let data = {
    danielalohel: {
        post: [
            "WkdGdWFXVnNZUzVzYjJobGJBPT0=",
            "UUhSeVlYVmxjbWhwYkdabA==",
            "TFd4dmFHVnNMbVJs"
        ],

        iterations: 2
    },
    kontakt: {
        post: [
            "YVc1bWIwQjBjbUYxWlhKb2FXeG1aUzFzYjJobGJDNWtaUT09"
        ],

        iterations: 2
    },
    datenschutz: {
        post: [
            "WkdGMFpXNXpZMmgxZEhwQWRISmhkV1Z5YUdsc1ptVXRiRzlvWld3dVpHVT0="
        ],

        iterations: 2
    }
};

function decode(tag,withPrefix) {
    let pre = "bWFpbHRv";
    let x = [...data[tag].post];
    for (let i=0; i < data[tag].iterations; i++) {
        for (let j=0; j < x.length; j++) {
            x[j] = atob(x[j]);
        }
    }
    let s = x.join("");
    return withPrefix ? (atob(pre) + ":" + s) : s;
}

export default function attachContact(elem,tag) {
    let e = $(elem);
    let fname = elem.substring(1);
    window[fname] = function() {
        window.location.href = decode(tag,true);
    }
    e.text(decode(tag,false));
    e.attr("href", "javascript:window['" + fname + "']()");
}