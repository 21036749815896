import $ from 'jquery';
import {Foundation} from 'foundation-sites';

Foundation.Abide.defaults.patterns['name'] = /^\s*[a-zäöüßA-ZÄÖÜ][a-zäöüßA-ZÄÖÜ\-\s]*$/;
Foundation.Abide.defaults.patterns['phone'] = /^\s*(?:00\d{2}|\+\d{2}|0)[1-9][0-9]{1,3}\s*\/?\-?\s*[1-9][0-9]{2,8}\s*$/;


export class ContactForm {

    static defaultOptions() {
        return {
            form: "#contact-form",
            phoneInput: "#contact-phone",
            phoneLabel: "#contact-phone-label",
            phoneLabelText: "Telefonnummer",
            isUrgentInput: "#contact-callback",
            successCallout: "#contact-form-sent",
            failCallout: "#contact-form-not-sent",
            spinner: "#contact-form-spinner",
            postUrl: "/handle-contact-form.php"
        };
    }

    constructor(options) {
        this.options = ContactForm.defaultOptions();
        if (options) {
            for (let x in options) {
                if (options[x]) {
                    if (this.options[x]) {
                        this.options[x] = options[x];
                    } else {
                        throw new Error("unknown option: " + x);
                    }
                }
            }
        }

        this.abide = new Foundation.Abide($(this.options.form));
        this.highlighted = new Map();    

        $(this.options.form).on("submit", this.submitForm.bind(this));
        $(this.options.isUrgentInput).on("input", this.requirePhone.bind(this));
    }

    highlight(id) {
        let $element = $(id);
        $element.addClass("highlight-shadow");
        let f = this.dim.bind(this,id);
        $element.on("input", f);
        this.highlighted.set(id,f);
    }

    dim(id) {
        let f = this.highlighted.get(id);
        if (f) {
            this.highlighted.delete(id);
            let $element = $(id);
            $element.off("input", f);
            $element.removeClass("highlight-shadow");
        }
    }


    requirePhone() {
        let cb = $(this.options.isUrgentInput);
        let p = $(this.options.phone);
        if (cb.prop("checked")) {
            $(this.options.phoneLabel).text(this.options.phoneLabelText + " *");
            p.attr("required", "");
            if (!p.val()) {
                this.highlight(this.options.phoneInput);
            }
        } else {
            p.removeAttr("required");
            $(this.options.phoneLabel).text(this.options.phoneLabelText);
            this.dim(this.options.phoneInput);
        }

        return false;
    }

    submitForm() {
        event.preventDefault();

        if (this.abide.validateForm()) {

            // lock the submit button until the AJAX request completes
            let $submit = $("button[type=submit]");
            $submit.attr("disabled", "true");

            // ensure that result callouts are hidden
            $(this.options.successCallout).addClass("hidden");
            $(this.options.failCallout).addClass("hidden");

            // show spinner
            $(this.options.spinner).removeClass("hidden");

            $.ajax({
                type: "POST",
                url: this.options.postUrl,
                data: $(this.options.form).serialize(), 
                contentType: "application/x-www-form-urlencoded",
                dataType: "text"
            }).done(() => {
                // show success callout
                $(this.options.successCallout).removeClass("hidden");
                $(this.options.form).trigger("reset");
            }).fail(() => {
                // show error success callout
                $(this.options.failCallout).removeClass("hidden");
            }).always(() => {
                // unlock the submit button
                $submit.removeAttr("disabled");
                $(this.options.spinner).addClass("hidden");
            });
        }

        return false;
    };

}