import $ from 'jquery';
import 'what-input';
import attachContact from './lib/emailObfuscation';
import { ContactForm } from './lib/contact-form';
import './lib/slick';

// Foundation JS relies on a global variable. In ES6, all imports are hoisted
// to the top of the file so if we used `import` to import Foundation,
// it would execute earlier than we have assigned the global variable.
// This is why we have to use CommonJS require() here since it doesn't
// have the hoisting behavior.
window.jQuery = $;
require('foundation-sites');

// If you want to pick and choose which modules to include, comment out the above and uncomment
// the line below
//import './lib/foundation-explicit-pieces';

$(document).foundation();

$("#banner-slideshow").slick({
    infinite: true,
    autoplay: true,
    autoplaySpeed: 10000,
    dots: false,
    fade: true,
    speed: 500,
    pauseOnHover: false,
});

var contactForm = new ContactForm();
attachContact("#trauerhilfe-lohel-kontaktieren", "kontakt");
attachContact("#datenschutzbeauftragter", "datenschutz");

window.toggleVisibility = ids => {
    ids.forEach(id => {
        let element = $(id);
        if (element) {
            if (element.hasClass("hidden")) {
                element.removeClass("hidden");
            } else {
                element.addClass("hidden");
            }
        }
    });
}